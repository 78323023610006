<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con">
                <div class="con_table">
                    <div class="con_top mb-10">
                        <h2><span>■</span> 기업 상세화면</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>기업명</th>
                                <td class="left pl-20">
                                    {{name}}
                                </td>
                                <th>기업코드</th>
                                <td class="left pl-20">
                                    {{ companyCode }}
                                </td>
                            </tr>
                            <tr>
                                <th>담당자</th>
                                <td class="left pl-20">
                                    {{manager}}
                                </td>
                                <th>연락처</th>
                                <td class="left pl-20">
                                    {{phone1}}-{{phone2}}-{{phone3}}
                                </td>
                            </tr>
                            <tr>
                                <th>계약기간</th>
                                <td class="left pl-20">
                                    {{consStartDate.slice(0,10)}} ~ {{consEndDate.slice(0,10)}}
                                </td>
                                <th>계약총액</th>
                                <td class="left pl-20">
                                    {{numberWithCommas(totalPrice)}} 원
                                </td>
                            </tr>
                            <tr>
                                <th>상담1회비용</th>
                                <td class="left pl-20">
                                    {{numberWithCommas(price)}} 원
                                </td>
                                <th>1인당 지원회기</th>
                                <td class="left pl-20">
                                    {{consCount}}회
                                </td>
                            </tr>
                            <tr>
                                <th>검사가능여부</th>
                                <td class="left pl-20">
                                    {{formatYN(testYn)}}
                                </td>
                                <th>가족지원여부</th>
                                <td class="left pl-20">
                                    {{formatYN(familyYn)}}
                                </td>
                            </tr>
                            <tr>
                                <th>유형</th>
                                <td class="left pl-20" colspan="3">
                                    {{type}}
                                </td>
                            </tr>
                            <tr>
                                <th>기업메모</th>
                                <td class="left pl-20 h-100px" colspan="3">
                                    {{memo}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="prev()">목록</a>
                        <a class="btn_cancle mr-10 pointer" @click="modify(idx)">수정</a>
                        <a class="btn_cancle mr-10 pointer" @click="remove(idx)">삭제</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        idx: 1, // 기업정보 고유 idx
        name: '', // 기업명
        manager: '', // 담당자
        phone1: '', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        consStartDate: '', // 계약시작일
        consEndDate: '', // 계약종료일
        totalPrice: '', // 계약총액
        price: '', // 상담1회비용
        consCount: '', // 지원회기
        testYn: 'Y', // 검사가능여부 Y/N
        familyYn: 'N', // 가족지원여부 Y/N
        type: '찾아가는상담', // 유형 (찾아가는상담/전화/화상/법률/채팅/대면상담)
        memo: '', // 기업메모
        companyCode:'',//기업코드
    }),

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idx

        this.getCompanyData(this.idx)
    },

    methods: {

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        
        // 기업 정보 데이터 api 호출
        getCompanyData(idx) {
            var params = {
                idx: idx
            }

            this.axios.get(`/api/v1/company/${idx}`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.name = res.data.company.name
                        this.manager = res.data.company.manager
                        this.consStartDate = res.data.company.cons_start_date
                        this.consEndDate = res.data.company.cons_end_date
                        this.totalPrice = res.data.company.total_price
                        this.price = res.data.company.price
                        this.consCount = res.data.company.cons_count
                        this.testYn = res.data.company.test_yn
                        this.familyYn = res.data.company.family_yn
                        this.type = res.data.company.type
                        this.memo = res.data.company.memo
                        this.companyCode = res.data.company.company_code;
                        // 휴대폰 번호 분할하기
                        if (res.data.company.phone.split('-').length === 3) {
                            var phone = res.data.company.phone.split('-')
                            this.phone1 = phone[0]
                            this.phone2 = phone[1]
                            this.phone3 = phone[2]
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 이전 페이지로 돌아가기
        prev() {
            this.$router.push('/origin/company_manage')
        },
        
        // Y/N 을 가능/불가능으로 출력
        formatYN (flag) {
            if (flag === 'Y') {
                return '가능'
            } else {
                return '불가능'
            }
        },

        // 기업수정 페이지로 이동
        modify(idx) {
            this.$router.push(`/origin/company_manage_modify?idx=${idx}`)
        },

        // 기업정보 삭제
        remove(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                var params = {
                    idx: idx
                }

                this.axios.delete(`/api/v1/company/${idx}`, params)
                    .then(res => {
                        if (res.data.err === 0) {
                            alert('삭제되었습니다')
                            this.getCompanyList()
                        } else {
                            alert(res.data.result)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        alert(err)
                    })
            } else {
                return false
            }
        }
    }
}
</script>
